import { PortableText } from '@ahmdigital/ui';
import generatePortableText from '@ahmdigital/logic/lib/content/utils/generate-portable-text';
import React from 'react';

import placeholderSerializer from '../../../serializers/placeholder';
import type { Offer, OfferRewards, Placeholder } from '../../../types';

type OfferHeadingProps = {
  header: any;
  offer: Offer;
  placeholders: Placeholder[];
  rewards: OfferRewards;
};

const OfferHeading = ({ header, offer, placeholders, rewards }: OfferHeadingProps) => (
  <PortableText
    typeSerializers={{
      placeholder: placeholderSerializer({
        offer,
        placeholders,
        rewards,
      }),
    }}
    source={generatePortableText(header)}
  />
);
export default OfferHeading;
