import { get } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';

export const GET_PLACEHOLDERS_QUERY = gql`
  query AllPlaceholder {
    allPlaceholder {
      _id
      name
      identifier {
        current
      }
    }
  }
`;

const useGetAllPlaceholders = ({ skip = false } = {}) => {
  const { data, loading } = useQuery(GET_PLACEHOLDERS_QUERY, { skip });
  return {
    loading,
    placeholders: get('allPlaceholder', data),
  };
};

export default useGetAllPlaceholders;
