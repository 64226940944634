import { Col, Row, SpacerWrapper } from '@ahmdigital/ui';
import React, { PropsWithChildren } from 'react';

import { TileVariantProvider } from '../../context';
import { VARIANT, VariantValue } from '../../constants';
import styles from '../../styles.module.sass';

type TileWrapperProps = PropsWithChildren<{
  variant: VariantValue;
  isBoxed: boolean;
}>;

const TileWrapper = ({ variant, isBoxed, children }: TileWrapperProps) => {
  const rowMargins = {
    [VARIANT.HEADER]: 2,
    [VARIANT.STRIP]: 6,
    [VARIANT.TILE]: 2,
    [VARIANT.NO_SPACING]: 0,
  };

  const rowMargin = rowMargins[variant];

  return (
    <TileVariantProvider value={variant}>
      <Row isBoxed={isBoxed}>
        <Col df={12}>
          <SpacerWrapper
            variant={[
              `paddingBottomSpacer${rowMargin}`,
              `paddingTopSpacer${rowMargin}`,
              variant === VARIANT.HEADER ? styles.headerSection : styles.section,
            ]}
          >
            {children}
          </SpacerWrapper>
        </Col>
      </Row>
    </TileVariantProvider>
  );
};

export default TileWrapper;
