import { Button, Col } from '@ahmdigital/ui';
import React, { ComponentProps } from 'react';

import { useTileVariant } from '../../context';
import { VARIANT } from '../../constants';
import ahmConstants from '../../../../../ahm-constants';
import HeaderWithSubtext from '../header-with-subtext';
import PromoCode from '../promo-code';
import styles from '../../styles.module.sass';

type ContentProps = ComponentProps<typeof PromoCode> &
  ComponentProps<typeof HeaderWithSubtext> & {
    showFindCover: boolean;
  };

const contentColProps = {
  [VARIANT.HEADER]: {
    df: 6,
    lg: 12,
  },
  [VARIANT.STRIP]: {
    df: 7,
    md: 12,
  },
  [VARIANT.TILE]: {
    df: 6,
    sm: 12,
  },
};

const Content = ({
  codeType,
  ends,
  header,
  offer,
  placeholders,
  promoCode,
  promoCodeDescription,
  referralUrl,
  showCodeCopy,
  showFindCover,
  showMemberLogin,
  subtext,
}: ContentProps) => {
  const variant = useTileVariant();

  return (
    // @ts-expect-error - Automatic, Please fix when editing this file
    <Col variant={variant === VARIANT.HEADER ? styles.copyWrapper : null} key="content" {...contentColProps[variant]}>
      <HeaderWithSubtext {...{ ends, header, offer, placeholders, subtext }} />
      <div className={styles.ctaWrapper}>
        {variant !== VARIANT.STRIP && (
          <PromoCode
            showMemberLogin={showMemberLogin}
            showCodeCopy={showCodeCopy}
            promoCode={promoCode}
            referralUrl={referralUrl}
            promoCodeDescription={promoCodeDescription}
            codeType={codeType}
          />
        )}
        {variant === VARIANT.HEADER && showFindCover && (
          <div className={styles.ctaButtonWrapper}>
            <Button
              icon={{
                name: 'arrow-right-long',
                position: 'right',
                size: 'small',
              }}
              variant={['flair', styles.ctaButton]}
              analytics={{ label: 'Header Show Products' }}
              to={ahmConstants.URLS.SALES.FIND_HEALTH_INSURANCE}
            >
              Find&nbsp;cover
            </Button>
          </div>
        )}
      </div>
    </Col>
  );
};

export default Content;
