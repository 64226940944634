import { gql } from '@apollo/client';

import { GRAPHQL } from '../../constants';

export default gql`
  fragment howToFields on HowTo {
    id: _id
    name
    slogan: sloganRaw
    steps {
      name
      description: descriptionRaw
      ${GRAPHQL.IMAGE_OBJECT}
      ${GRAPHQL.URL_OBJECT}
    }
    ${GRAPHQL.URL_OBJECT}
    importance
  }
`;
