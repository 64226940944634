import { get, includes } from 'lodash/fp';

import ahmConstants from '../../../../../ahm-constants';
import type { Offer } from '../../../types';

const getFindCoverCtaContent = (offer: Offer) => {
  if (includes('extras-only', get('identifier.current', offer))) {
    return {
      name: 'Find extras only cover',
      url: ahmConstants.URLS.SALES.PRODUCT_LISTING_EXTRAS,
    };
  }

  if (includes('hospital-only', get('identifier.current', offer))) {
    return {
      name: 'Find hospital only cover',
      url: ahmConstants.URLS.SALES.PRODUCT_LISTING_HOSPITAL,
    };
  }

  return {
    name: 'Find hospital and extras cover',
    url: ahmConstants.URLS.SALES.PRODUCT_LISTING_PACKAGES,
  };
};

export default getFindCoverCtaContent;
