import { createContext, useContext } from 'react';

import { VariantValue } from './constants';

const TileVariantContext = createContext<VariantValue | null>(null);

export const useTileVariant = () => {
  const variant = useContext(TileVariantContext);

  return variant;
};

export const TileVariantProvider = TileVariantContext.Provider;
