import { Button, CodeCopy } from '@ahmdigital/ui';
import { kebabCase } from 'lodash/fp';
import React from 'react';
import styled from 'styled-components';

import constants from '../../../../../ahm-constants';

type PromoCodeProps = {
  codeType?: string;
  displayFullWidth?: boolean;
  promoCode?: string;
  promoCodeDescription?: string;
  referralUrl?: string;
  showCodeCopy: boolean;
  showMemberLogin: boolean;
};

const CodeCopyStyled = styled(CodeCopy)`
  width: auto;

  @media (min-width: ${({ theme }) => theme.media.lg}) {
    width: ${({ displayFullWidth }) => (displayFullWidth ? 'auto' : '20rem')};
  }
`;

const PromoCode = ({
  codeType,
  displayFullWidth,
  promoCode,
  promoCodeDescription,
  referralUrl,
  showCodeCopy,
  showMemberLogin,
}: PromoCodeProps) => {
  if (showMemberLogin) {
    return <Button to={constants.URLS.MEMBERS.REFER_A_FRIEND}>Log in &amp; get your referral code</Button>;
  }

  if (showCodeCopy && promoCode) {
    return (
      <CodeCopyStyled
        displayFullWidth={displayFullWidth}
        labelId={`code-copy-label-for-${kebabCase(promoCode)}`}
        codeValue={referralUrl || promoCode}
        label={promoCodeDescription}
        title={`Copy ${codeType} code`}
      />
    );
  }

  return null;
};

PromoCode.defaultProps = {
  codeType: null,
  displayFullWidth: false,
  promoCode: null,
  promoCodeDescription: null,
  referralUrl: null,
};

export default PromoCode;
