import { Col, Image } from '@ahmdigital/ui';
import { compact } from 'lodash/fp';
import React from 'react';

import { useTileVariant } from '../../../../context';
import { VARIANT } from '../../../../constants';
import styles from './styles.module.sass';
import type { ImageType } from '../../../../../../../types/image';

type ImageProps = {
  className?: string;
  image: ImageType;
};

const imageColProps = {
  df: 6,
  lg: 12,
};

const OfferImage = ({ image, className }: ImageProps) => {
  const variant = useTileVariant();

  if (variant !== VARIANT.STRIP && image) {
    return (
      <Col variant={compact([styles.imageWrapper, className])} key="image" {...imageColProps}>
        <Image className={styles.image} src={image.src} alt={image.alt} />
      </Col>
    );
  }

  return null;
};

OfferImage.defaultProps = {
  className: '',
};

export default OfferImage;
